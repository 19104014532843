.container {

    // height: 200px;
    // width: 400px;
    display: flex;
    padding: 20px;
    gap: 15px;
}
.img {
    height: 100px;
    width: 100px;
    border-radius: 10px;
}
.name {
    font-size: large;
    font-weight: bold;
    margin-bottom: 0px !important;
}

.designation {
    font-size: 14px;
    font-weight: bold;
}
.phone {
    
}
@primary-color: #48c75b;