/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.list {
  max-height: 400px;
  overflow: auto;
}
.list .item {
  transition: all 0.3s;
  overflow: hidden;
  cursor: pointer;
  padding-left: 24px;
  padding-right: 24px;
}
.list .item .meta {
  width: 100%;
}
.list .item .avatar {
  background: #fff;
  margin-top: 4px;
}
.list .item .iconElement {
  font-size: 32px;
}
.list .item.read {
  opacity: 0.4;
}
.list .item:last-child {
  border-bottom: 0;
}
.list .item:hover {
  background: #f0fff0;
}
.list .item .title {
  font-weight: normal;
  margin-bottom: 8px;
}
.list .item .description {
  font-size: 12px;
  line-height: 1.5715;
}
.list .item .datetime {
  font-size: 12px;
  margin-top: 4px;
  line-height: 1.5715;
}
.list .item .extra {
  float: right;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  margin-right: 0;
  margin-top: -1.5px;
}
.notFound {
  text-align: center;
  padding: 73px 0 88px 0;
  color: rgba(0, 0, 0, 0.45);
}
.notFound img {
  display: inline-block;
  margin-bottom: 16px;
  height: 76px;
}
.clear {
  height: 46px;
  line-height: 46px;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 0 0 2px 2px;
  border-top: 1px solid #f0f0f0;
  transition: all 0.3s;
  cursor: pointer;
}
.clear:hover {
  color: rgba(0, 0, 0, 0.85);
}
