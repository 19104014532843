

.ant-drawer-body {
    padding: 0px !important;
}

.ql-snow .ql-editor h5 {
    font-size: 1.4em;
}

.left .ant-form-item-label {
    text-align: left;
}

#cardCont::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.noPadding {
    padding: 0;
    margin: 0;
}

#hiringQuestion .ant-radio-group label.ant-radio-button-wrapper {
    margin-right: 10px;
    margin-bottom: 10px;
    display: block;
}


#hiringQuestion .ant-form-item-label label {
    display: inline;
}

#hiringQuestion .ant-form-item-label label > div {
    display: inline;
}


#hiringQuestion li.ant-rate-star.ant-rate-star-zero .anticon {
    color: #959595;
}

#commentClass .ant-popover-inner-content {
    width: 400px;
}
#commentList ul.ant-list-item-action{
    margin-top: 0;
}
.form{
    margin-top:20px;
}

